.inactive-5 {
  opacity: 0.5;
  cursor: default;
}

.inactive-7 {
  opacity: 0.7;
  cursor: default;
}

#back-button.hidden {
  display: none !important;
}

.fill {
  width: 100vw;
  overflow-x: hidden;
  background-color: #ECEFF1;
  height: 15px;
  z-index: 2;
  transform: translateY(-15px);
}
@media screen and (max-width: 700px) {
  .fill {
    transform: translateY(-15px);
  }
}

.login-cargr {
  z-index: 1;
  width: 100vw;
  overflow-x: hidden;
  background-color: #ECEFF1;
  font-family: verdana, arial, sans-serif;
  font-weight: 700;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-15px);
}
@media screen and (max-width: 700px) {
  .login-cargr {
    transform: translateY(-15px);
  }
}
.login-cargr .container {
  z-index: 3;
  background-color: white;
  max-width: 575px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}
@media screen and (max-width: 300px) {
  .login-cargr .container {
    width: 90%;
  }
}
.login-cargr .container .container__head {
  border-bottom: solid 1px #d4d4d4;
}
.login-cargr .container .container__head h2 {
  font-size: 18px;
  color: #0652b6;
  text-align: center;
  padding: 12px;
}
.login-cargr .container .container__alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2px;
  height: 75px;
  overflow: hidden;
  margin: 0;
  max-height: 0;
}
.login-cargr .container .container__alert .status {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.login-cargr .container .container__alert .status p {
  font-size: 12px;
  font-weight: 600;
}
.login-cargr .container .container__alert .status img {
  padding-left: 15px;
  height: 20px !important;
  display: none;
}
.login-cargr .container .container__alert.container__alert-active {
  max-height: 100%;
  margin: 15px 25px 0;
}
.login-cargr .container .container__alert.loading {
  background-color: whitesmoke;
  border: 1px solid #d4d4d4;
}
.login-cargr .container .container__alert.loading p {
  color: rgb(51, 51, 51);
}
.login-cargr .container .container__alert.loading img {
  display: initial;
  margin: 0;
}
.login-cargr .container .container__alert.ok {
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
}
.login-cargr .container .container__alert.ok p {
  color: #468847;
}
.login-cargr .container .container__alert.err {
  background-color: #f2dede;
  border: 1px solid #eed3d7;
}
.login-cargr .container .container__alert.err p {
  color: #b94a48;
}
.login-cargr .container .container__form {
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 7.5px;
}
.login-cargr .container .container__form .form__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 410px) {
  .login-cargr .container .container__form .form__row {
    flex-direction: column;
    align-items: start;
  }
}
.login-cargr .container .container__form .form__row .form-row__heading {
  margin-right: 20px;
  font-size: 13px;
  color: rgb(51, 51, 51);
}
@media screen and (max-width: 410px) {
  .login-cargr .container .container__form .form__row .form-row__heading {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 300px) {
  .login-cargr .container .container__form .form__row div {
    width: 100%;
  }
}
.login-cargr .container .container__form .form__row input {
  font-size: 12px;
  color: rgb(85, 85, 85);
  border: 1px solid #ccc;
  height: 34px;
  width: 220px;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
@media screen and (max-width: 300px) {
  .login-cargr .container .container__form .form__row input {
    width: 100%;
  }
}
.login-cargr .container .container__form .form__row input:focus {
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
}
.login-cargr .container .container__form .form__container-login-btn {
  display: flex;
  justify-content: right;
  padding-top: 7px;
}
.login-cargr .container .container__form .form__container-login-btn button {
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  font-weight: normal;
  color: white;
  border-radius: 4px;
  height: 34px;
  width: 220px;
  border-radius: 8px;
  background-color: #065fd4;
}
.login-cargr .container .container__form .form__container-login-btn button:hover {
  border: 0;
  background-color: #0654ba;
}
.login-cargr .container .container__form .form__container-login-btn button:active {
  border: 0;
  background-color: #0864dc;
}
.login-cargr .bottom-label {
  z-index: 3;
  padding-top: 18px;
}
.login-cargr .bottom-label h1 {
  color: #bebebe;
  font-size: 13px;
  font-weight: 400;
}
.login-cargr .bottom-label h2 {
  margin-top: 15px;
  color: #0652b6;
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.cargr-connect {
  overflow: hidden;
}/*# sourceMappingURL=login-cargr.css.map */