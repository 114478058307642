.demo-banner {
    width: 100vw;
    background-color: rgb(220, 220, 50);
    padding: 5px;
    font-size: 90%;
    @media screen and (max-width: 768px) {
        padding: 10px 20px;
    }
}

.data-panel1 {
    // border: 0;
    margin-top: 36px;
    .panel-heading {
        font-size: 24px;
        // padding: 0;
        // padding-bottom: 18px;
        // padding-bottom: 36px;
    }
    .panel-content {
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 890px) {
            flex-direction: column;
            .info-box {
                margin-bottom: 24px;
                &:nth-child(3) {
                    margin-bottom: 0;
                }
            }
        }
        .info-box {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 890px) {
                justify-content: flex-start;
            }
            .info-box-img {
                width: 50px;
                min-width: 50px;
                height: 50px;
                min-height: 50px;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
                img {
                    height: 30px;
                    width: 30px;
                }
            }
            .info-box-right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .info-box-heading {
                    font-size: 12px;
                }
                .info-box-content {
                    font-weight: 600;
                    font-size: 28px;
                    margin-bottom: 4px;
                }
                .info-box-bottom {
                    font-size: 12px;
                    // text-align: left;
                }
            }
        }
    }
}
//// V3 ////
.data-panel2 {
    margin-top: 36px;
    margin-bottom: 36px;
    border: 0;
    .panel-heading {
        font-size: 24px;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .panel-content {
        padding: 0;
        display: flex;
        justify-content: center;
        font-size: 12px;
        table {
            font-size: 14px;
            width: 100%;
            thead {
                tr {
                    padding: 24px 0;
                    display: grid;
                    grid-template-rows: 1fr;
                    justify-items: start;
                    align-items: center;
                    grid-template-columns: 0.75fr 1fr 1fr 140px;
                    @media screen and (max-width: 1200px) {
                        grid-template-columns: 0.70fr 1fr 1fr 140px;
                    }
                    @media screen and (max-width: 1050px) {
                        grid-template-columns: 0.65fr 1fr 1fr 140px;
                    }
                    @media screen and (max-width: 900px) {
                        grid-template-columns: 0.60fr 1fr 1fr 140px;
                    }
                    @media screen and (max-width: 800px) {
                        grid-template-columns: 0.55fr 1fr 1fr 140px;
                    }
                    @media screen and (max-width: 700px) {
                        grid-template-columns: 0.50fr 1fr 1fr 140px;
                    }
                    // @media screen and (max-width: 650px) {
                    //     grid-template-columns: 0.45fr 1fr 1fr 140px;
                    // }
                    th {
                        color: #606060;
                        font-weight: 800;
                        margin-left: 15px;
                        margin-right: 15px;
                        text-align: left;
                    }
                }
            }
            tbody {
                .line{
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                    display: none;
                }
                tr {
                    &:nth-of-type(odd) {
                        border-radius: 4px;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        background-color: rgba(40, 47, 95, 0.05);
                    }
                    padding: 20px 0;
                    display: grid;
                    grid-template-rows: 1fr;
                    justify-items: start;
                    align-items: center;
                    grid-template-columns: 0.75fr 1fr 1fr 140px;
                    @media screen and (max-width: 1200px) {
                        grid-template-columns: 0.70fr 1fr 1fr 140px;
                    }
                    @media screen and (max-width: 1050px) {
                        grid-template-columns: 0.65fr 1fr 1fr 140px;
                    }
                    @media screen and (max-width: 900px) {
                        grid-template-columns: 0.60fr 1fr 1fr 140px;
                    }
                    @media screen and (max-width: 800px) {
                        grid-template-columns: 0.55fr 1fr 1fr 140px;
                    }
                    @media screen and (max-width: 700px) {
                        grid-template-columns: 0.50fr 1fr 1fr 140px;
                    }
                    // @media screen and (max-width: 650px) {
                    //     grid-template-columns: 0.45fr 1fr 1fr 140px;
                    // }
                    td {
                        grid-row: 1;
                        margin-left: 15px;
                        margin-right: 15px;
                    }
                    td.no {
                        text-align: left;
                        font-weight: 600;
                    }
                    td.date_time {
                        text-align: left;
                        .date_time-inner {
                            width: fit-content;
                            margin: 0;
                            .day-of-week {
                                &::after {
                                    content: "•";
                                    margin: 0 5px;
                                }
                            }
                            .date {
                                &::after {
                                    content: "•";
                                    margin: 0 5px;
                                }
                            }
                            .time {
                            }
                        }
                    }
                    td.category {
                        text-align: left;
                        background-color: rgba(124, 185, 199, 0.2);
                        padding: 8px;
                        margin: -8px;
                        margin-left: 8px;
                        border-radius: 16px;
                        .number-updated-inner {
                            display: none;
                        }
                        // .category-inner {
                        //     }
                        //     span {
                        //     }
                        // }
                    }
                    td.number-updated {
                        // justify-self: end;
                        .number-updated-inner {
                            display: flex;
                            align-items: center;
                            .check {
                                margin: 0;
                                background-color: #606060;
                                // background-color: #333333;
                                border-radius: 100%;
                                padding: 1px;
                                width: 10px;
                                height: 10px;
                                margin-right: 5px;
                            }
                            span {
                                color: #606060;
                                color: rgb(51, 51, 51);
                                // color: #333333;
                            }
                        }
                    }
                }
            }
        }
    }
}
.data-panel2 {
    @media screen and (max-width: 650px) {
        border: 0;
        .panel-heading {
            border: 1px solid rgba(0,0,0,0.1);
            border-bottom: 0;
            border-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .panel-content {
            padding: 0;
            table {
                thead {
                    display: none;
                }
                tbody {
                    .line:nth-child(1) {
                        display: none;
                    }
                    .line{
                        display: none;
                    }
                    tr {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        padding: 20px 0;
                        border-radius: 4px;
                        td {
                            margin-left: 20px !important;
                            margin-right: 20px !important;
                            padding: 0;
                            margin-bottom: 4px;
                            &:nth-last-child(1) {
                                margin-bottom: 0;
                            }
                        }
                        td.no.active {
                            &::before {
                                content: "Ανανέωση";
                                margin-right: 5px;
                            }
                        }
                        td.date_time {
                            color: #606060;
                            .date_time-inner {
                                .day-of-week {
                                }
                                .date {
                                }
                                .time {
                                }
                            }
                        }
                        td.category {
                            margin-left: 15px;
                            font-size: 90%;
                            color: #606060;
                            background-color: rgba(0,0,0,0);
                            padding: 0;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            // .category-inner {
                            //     background-color: rgb(124, 185, 199);
                            //     border-radius: 8px;
                            //     margin: -1px -3px;
                            //     padding: 1px 3px;
                            //     margin-left: 0;
                            // }
                            .number-updated-inner {
                                &::after {
                                    content: "•";
                                    margin: 0 5px;
                                }
                                display: flex;
                                align-items: center;
                                img {
                                    background-color: #606060;
                                    border-radius: 100%;
                                    padding: 1px;
                                    width: 8px;
                                    height: 8px;
                                    margin-right: 5px;
                                    margin: 0;
                                    margin-right: 5px;
                                    margin-bottom: 1px;
                                }
                                span {
                                    color: rgb(51, 51, 51);
                                    color: #606060;
                                }
                            }
                        }
                        td.number-updated {
                            display: none;
                            grid-row: 3;
                            grid-column: 1;
                            .number-updated-inner {
                                .check {
                                }
                                span {
                                    &::after {
                                        content: "•";
                                        margin: 0 5px;
                                    }
                                }
                            }
                        }
                    }
                    tr.flat-b-r-top {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }
        }
    }
}
