nav#nav-bar.hidden {
  display: none !important;
}

nav#nav-bar {
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;
  padding-right: 30px;
}
@media screen and (max-width: 700px) {
  nav#nav-bar {
    padding-right: 15px;
  }
}
nav#nav-bar div.nav-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17.5px;
  padding-bottom: 17.5px;
}
nav#nav-bar div.nav-top div.menu-img {
  display: none;
}
@media screen and (max-width: 700px) {
  nav#nav-bar div.nav-top div.menu-img {
    cursor: pointer;
    display: initial;
  }
}
nav#nav-bar div.nav-top div.menu-img img {
  width: 24px;
  height: 24px;
  margin: 0;
}
nav#nav-bar div.nav-top .first {
  margin: -4px;
  margin-left: 0;
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 700px) {
  nav#nav-bar div.nav-top .first {
    display: none;
  }
}
nav#nav-bar div.nav-top ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 700px) {
  nav#nav-bar div.nav-top ul {
    display: none;
  }
}
nav#nav-bar div.nav-top ul li {
  padding: 0 100px;
}
@media screen and (max-width: 1100px) {
  nav#nav-bar div.nav-top ul li {
    padding: 0 80px;
  }
}
@media screen and (max-width: 950px) {
  nav#nav-bar div.nav-top ul li {
    padding: 0 60px;
  }
}
nav#nav-bar div.nav-top div.logout {
  cursor: pointer;
}
nav#nav-bar div.nav-top div.logout img {
  width: 20px;
  height: 20px;
  margin: 0;
}
nav#nav-bar div.nav-top div.logout img.google-img {
  border-radius: 100%;
  width: 28px;
  height: 28px;
  margin: -4px;
  margin-right: 0;
}
nav#nav-bar div.nav-top div.logout .user_image_wrapper {
  width: 20px;
  height: 20px;
  margin: 0;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  margin: -4px;
  margin-right: 0;
  background-color: #b6b6b6;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav#nav-bar div.nav-top div.logout .user_image_wrapper img {
  margin: 0;
  width: 18px;
  height: 18px;
}
nav#nav-bar div.nav-top div.logout .user_image_wrapper:active {
  background-color: #d7d7d7;
}
nav#nav-bar div.nav-mobile {
  display: none;
  max-height: 0;
}
nav#nav-bar div.nav-mobile.expanded {
  max-height: 100%;
  display: flex;
  justify-content: start;
}
nav#nav-bar div.nav-mobile.expanded ul {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}
nav#nav-bar div.nav-mobile.expanded ul li {
  display: flex;
  justify-content: start;
  text-align: left;
  margin-top: 18px;
  margin-bottom: 18px;
}

.sign-out-diag {
  display: none;
}

.sign-out-diag + .diag-screen {
  display: none;
}

.sign-out-diag.expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 61px;
  right: 10px;
  border-radius: 4px;
  padding: 0 15px;
  color: #dadce1;
  max-width: 300px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
  z-index: 100;
  margin-left: 10px;
}
@media screen and (max-width: 700px) {
  .sign-out-diag.expanded {
    margin: 0;
    right: 0;
    top: 71px;
    max-width: none;
    min-width: none;
    width: 100%;
  }
}
.sign-out-diag.expanded .user {
  font-weight: 700;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: default;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.sign-out-diag.expanded .user img {
  margin: 0;
  width: 28px;
  height: 28px;
  margin-right: 15px;
  border-radius: 100%;
}
.sign-out-diag.expanded .line {
  width: 100%;
  border-bottom: 1px solid #dadce1;
  margin: 15px 0;
}
.sign-out-diag.expanded .sign-out-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  font-size: 14px;
}

.sign-out-diag.expanded + .diag-screen {
  z-index: 99;
  display: initial;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.sign-out-diag-inner {
  background-color: #242526;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
}/*# sourceMappingURL=nav.css.map */