#mode-buttons {
  display: flex;
}
@media screen and (max-width: 350px) {
  #mode-buttons {
    flex-direction: column;
  }
}
#mode-buttons div:nth-child(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media screen and (max-width: 350px) {
  #mode-buttons div:nth-child(1) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
#mode-buttons div:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media screen and (max-width: 350px) {
  #mode-buttons div:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
}
#mode-buttons div.inactive {
  color: rgb(6, 95, 212);
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
#mode-buttons div.inactive:hover {
  border: 0;
  background-color: rgb(214, 221, 255);
}
#mode-buttons div.inactive:active {
  border: 0;
  background-color: rgb(105, 230, 209);
}

.drag-n-drop .app-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}
.drag-n-drop h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.drag-n-drop .item-list {
  list-style: none;
  padding: 0;
}
.drag-n-drop .list-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drag-n-drop .list-item div {
  display: flex;
  align-items: center;
}
.drag-n-drop button {
  background-color: #065fd4;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
}
.drag-n-drop button:hover {
  background-color: #0654ba;
}
.drag-n-drop button:active {
  background-color: #0864dc;
}
.drag-n-drop button.remove {
  background-color: #d93025;
}
.drag-n-drop button.remove:hover {
  background-color: #ac2a20;
}
.drag-n-drop button.remove:active {
  background-color: #e43e32;
}
.drag-n-drop .add-item {
  margin-top: 20px;
}
.drag-n-drop label {
  font-weight: bold;
  margin-right: 10px;
}
.drag-n-drop input[type=text],
.drag-n-drop input[type=number],
.drag-n-drop select {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.drag-n-drop button[type=button] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}
.drag-n-drop button[type=button]:hover {
  background-color: #0056b3;
}
.drag-n-drop .add {
  margin-top: 10px;
}
.drag-n-drop li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  color: rgb(48, 48, 48);
}
.drag-n-drop li.new-item-text {
  background-color: #d6dde7;
}
.drag-n-drop li.new-item-number {
  background-color: #f4c7ad;
}
.drag-n-drop img {
  margin: 0;
  height: 25px;
  margin-right: 10px;
  cursor: grab;
}
@media screen and (max-width: 400px) {
  .drag-n-drop img.grip {
    display: none;
  }
}
.drag-n-drop .arrow-buttons {
  display: none;
}
.drag-n-drop .arrow-buttons button:nth-child(1) {
  margin-right: 10px;
}
.drag-n-drop .arrow-buttons button img {
  height: 20px;
  width: 20px;
}
@media screen and (max-width: 400px) {
  .drag-n-drop .arrow-buttons {
    display: initial;
    display: flex;
  }
}
.drag-n-drop .li-content {
  display: grid;
  grid-template-columns: 250px 250px 200px;
  width: 100%;
}
.drag-n-drop .li-content div {
  display: flex;
  flex-direction: column;
}
.drag-n-drop .li-content div span:nth-child(1) {
  margin-bottom: 2px;
}
@media screen and (max-width: 400px) {
  .drag-n-drop ul li {
    flex-direction: column;
  }
}
@media screen and (max-width: 400px) {
  .drag-n-drop ul li img {
    margin-right: 0;
  }
}
@media screen and (max-width: 400px) {
  .drag-n-drop ul li button {
    margin-left: 0;
  }
}
@media screen and (max-width: 900px) {
  .drag-n-drop ul li .li-content {
    grid-template-columns: 200px 200px;
    grid-template-rows: 40px 45px;
    align-items: center;
  }
}
@media screen and (max-width: 600px) {
  .drag-n-drop ul li .li-content {
    grid-template-columns: 200px;
    grid-template-rows: 45px 45px 50px;
    align-items: center;
  }
}
@media screen and (max-width: 300px) {
  .drag-n-drop ul li .li-content {
    grid-template-columns: 150px;
    grid-template-rows: 60px 45px 50px;
    align-items: center;
  }
}
.drag-n-drop .add {
  height: 34px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .drag-n-drop .add {
    flex-direction: column;
    align-items: flex-start;
    height: -moz-fit-content;
    height: fit-content;
  }
  .drag-n-drop .add button {
    margin-left: 0;
  }
  .drag-n-drop .add select {
    margin-bottom: 5px;
  }
  .drag-n-drop .add input {
    margin-bottom: 5px;
  }
}
.drag-n-drop .add input {
  padding: 6.2px;
}
.drag-n-drop .add .numstl {
  width: 100%;
}/*# sourceMappingURL=drag-and-drop-list.css.map */