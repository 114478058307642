///V2
body.login-page {
    overflow: hidden;
    font-family: verdana, arial, sans-serif;
    // background-color: #ECEFF1;
    background-color: #1a3644;
    font-weight: 700;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;

    #login {
        transform: translateY(-30px);
        position: relative;
    }

    #wrapper-background-image {
        overflow: visible;
        width: 100%;
        height: 100%;
        opacity: 4%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .wrapper-front {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .header {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            // margin-bottom: 15px;
            .heading {
                // color: rgb(55, 55, 55);
                color: #ffff;
                font-weight: 500;
            }
            .request-account > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                div {
                    // color: rgb(55, 55, 55) !important;
                    color: #ffff !important;
                }
            }
            .request-account {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 3px;
                div {
                    display: flex;
                    gap: 5px;
                    &:nth-child(2) {
                        color: rgb(119, 119, 119);
                    }
                    a {
                        color: #4285f4;
                        text-decoration: underline;
                    }
                }
            }
        }
        .container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            padding: 30px;
            border-radius: 2px;
            // background-color: #ffff;
            // box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
            
            .google-login{
                display: flex;
                justify-content: center;
                width: 100%;
            }
            .divider-line {
                margin-top: 25px;
                margin-bottom: 25px;
                width: 100%;
                border-bottom: 1px solid rgb(212, 212, 212);
                display: flex;
                justify-content: center;
                .text {
                    padding: 0 7px;
                    background-color: white;
                    color: rgb(119, 119, 119);
                    text-align: center;
                    position: absolute;
                    transform: translate3d(0, -8px, 0);
                }
                display: none;
            }
    
            .login-field {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                display: none;
    
                .login-field__heading {
                    z-index: 1;
                    position: absolute;
                    color: rgb(119, 119, 119);
                    transform: translate3d(10px, 0, 0);
                    transition: all .1s;
    
                }
                .login-field__input {
                    z-index: 2;
                    background-color: transparent;
                    width: 100%;
                    border: 0; border-bottom: 1px solid rgb(212, 212, 212);
                    // border: 1px solid rgb(212, 212, 212);
                    padding: 15px 10px;
                    
                    &:focus + .login-field__heading {
                        transform: translate3d(10px, -25px, 0);
                        font-size: 10px;
    
                    }
                    &:valid + .login-field__heading {
                        transform: translate3d(10px, -25px, 0);
                        font-size: 10px;
                    }
                    // &:focus {
                    //     border-bottom: 1px solid rgb(142, 151, 202);
                    // }
                }
            }
            .login-options {
                display: flex;
                align-items: center;
                gap: 5px;
                color: rgb(55, 55, 55);
                display: none;
            }
            .forgot-password {
                margin-top: 15px;
                color: #4285f4;
                text-decoration: underline;
                display: none;
            }
            .login-btn {
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 1px;
                height: 50px;
                width: 240px;
                color: #ffff;
                background-color: #4285f4;
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
                text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
                font-size: 14px;
                transition: background-color .218s,border-color .218s,box-shadow .218s;
                
                &:hover {
                    box-shadow: 0 0 3px 3px rgb(66 133 244 / 30%);
                }
                &:active {
                    background-color: #3367d6;
                    box-shadow: 0 0 3px 3px rgb(66 133 244 / 30%);
                }
            }
        }
        .container > .login-btn {
            display: none;
        }
        .g_logo-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: fit-content;
            background-color: white;
            border-radius: 100%;
            padding: 3px;
            margin: -3px;
            margin-right: 15px;
            min-width: 20px;
            min-height: 20px;
            .g_logo {
                margin: -2px;
                width: 22px;
                height: 22px;
                min-width: 20px;
                min-height: 20px;
            }
        }
    }
}
// ///V1
// body.login-page {
//     overflow: hidden;
//     font-family: verdana, arial, sans-serif;
//     background-color: #ECEFF1;
//     font-weight: 700;
//     height: 100vh;
//     width: 100vw;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     font-size: 12px;
//     font-weight: 500;

//     .header {
//         display: flex;
//         flex-direction: column;
//         justify-content: flex-start;
//         align-items: center;
//         gap: 5px;
//         margin-bottom: 15px;
//         .heading {
//             color: rgb(55, 55, 55);
//             font-weight: 500;
//         }
//         .request-account {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             gap: 3px;
//             div {
//                 display: flex;
//                 gap: 5px;
//                 &:nth-child(2) {
//                     color: rgb(119, 119, 119);
//                 }
//                 a {
//                     color: #4285f4;
//                     text-decoration: underline;
//                 }
//             }
//         }
//     }
//     .container {
//         width: 300px;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         gap: 15px;
//         padding: 30px;
//         border-radius: 2px;
//         background-color: #ffff;
//         box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
        
//         .google-login{
//             display: flex;
//             justify-content: center;
//             width: 100%;
//         }
//         .divider-line {
//             margin-top: 25px;
//             margin-bottom: 25px;
//             width: 100%;
//             border-bottom: 1px solid rgb(212, 212, 212);
//             display: flex;
//             justify-content: center;
//             .text {
//                 padding: 0 7px;
//                 background-color: white;
//                 color: rgb(119, 119, 119);
//                 text-align: center;
//                 position: absolute;
//                 transform: translate3d(0, -8px, 0);
//             }
//         }

//         .login-field {
//             width: 100%;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: flex-start;

//             .login-field__heading {
//                 z-index: 1;
//                 position: absolute;
//                 color: rgb(119, 119, 119);
//                 transform: translate3d(10px, 0, 0);
//                 transition: all .1s;

//             }
//             .login-field__input {
//                 z-index: 2;
//                 background-color: transparent;
//                 width: 100%;
//                 border: 0; border-bottom: 1px solid rgb(212, 212, 212);
//                 // border: 1px solid rgb(212, 212, 212);
//                 padding: 15px 10px;
                
//                 &:focus + .login-field__heading {
//                     transform: translate3d(10px, -25px, 0);
//                     font-size: 10px;

//                 }
//                 &:valid + .login-field__heading {
//                     transform: translate3d(10px, -25px, 0);
//                     font-size: 10px;
//                 }
//                 // &:focus {
//                 //     border-bottom: 1px solid rgb(142, 151, 202);
//                 // }
//             }
//         }
//         .login-options {
//             display: flex;
//             align-items: center;
//             gap: 5px;
//             color: rgb(55, 55, 55);
//         }
//         .forgot-password {
//             margin-top: 15px;
//             color: #4285f4;
//             text-decoration: underline;
//         }
//         .login-btn {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             padding-bottom: 1px;
//             height: 50px;
//             width: 240px;
//             color: #ffff;
//             background-color: #4285f4;
//             box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
//             text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
//             font-size: 14px;
//             transition: background-color .218s,border-color .218s,box-shadow .218s;
            
//             &:hover {
//                 box-shadow: 0 0 3px 3px rgb(66 133 244 / 30%);
//             }
//             &:active {
//                 background-color: #3367d6;
//                 box-shadow: 0 0 3px 3px rgb(66 133 244 / 30%);
//             }
//         }
//     }
// }