.panel {
  color: #333333 !important;
  margin: 18px 24px;
  margin-bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
}
@media screen and (max-width: 768px) {
  .panel {
    margin: 18px;
    margin-bottom: 0;
  }
  .panel .panel-heading {
    text-align: center;
  }
}
.panel .panel-heading {
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.panel .panel-content {
  padding: 24px;
}

.data-panel-settings {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 20px 24px 0;
  color: #606060;
  align-self: center;
}
.data-panel-settings .settings-head {
  padding: 20px 0 40px;
  display: flex;
  flex-direction: column;
}
.data-panel-settings .settings-head .settings-heading {
  font-size: 24px;
  color: rgb(15, 15, 15);
}
.data-panel-settings .settings-head .settings-subheading {
  padding-top: 8px;
}
.data-panel-settings .settings-group {
  text-align: left;
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.data-panel-settings .settings-group .settings-group-head {
  margin-bottom: 40px;
}
.data-panel-settings .settings-group .settings-group-head .settings-group-heading {
  font-weight: 500;
  font-size: 16px;
  color: rgb(15, 15, 15);
}
.data-panel-settings .settings-group .settings-group-head .settings-group-sub-heading {
  padding-top: 8px;
}
.data-panel-settings .settings-group .settings-group-body .settings-item {
  margin-bottom: 20px;
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: 160px 1fr;
}
@media screen and (max-width: 480px) {
  .data-panel-settings .settings-group .settings-group-body .settings-item {
    grid-template-columns: 1fr;
    grid-template-rows: 1 1;
    margin-top: 40px;
  }
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-heading {
  color: rgb(15, 15, 15);
  min-width: 160px;
  font-weight: 500;
  text-align: left;
}
@media screen and (max-width: 480px) {
  .data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-heading {
    margin-bottom: 20px;
  }
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options {
  padding-left: 54px;
}
@media screen and (max-width: 480px) {
  .data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options {
    padding-left: 0;
  }
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options .settings-item-option {
  max-width: 700px;
  display: flex;
  align-items: self-start;
  margin-bottom: 20px;
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options .settings-item-option:last-child {
  margin-bottom: 0;
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options .settings-item-option .settings-item-option-action {
  margin-right: 20px;
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options .settings-item-option .settings-item-option-action input {
  background-color: transparent;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 18px;
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options .settings-item-option .settings-item-option-head {
  display: flex;
  flex-direction: column;
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options .settings-item-option .settings-item-option-head .settings-item-option-heading {
  color: rgb(15, 15, 15);
  font-weight: 500;
  padding-bottom: 4px;
}
.data-panel-settings .settings-group .settings-group-body .settings-item .settings-item-options .settings-item-option .settings-item-option-head .settings-item-option-heading.action {
  cursor: pointer;
  color: rgb(62, 166, 255);
  color: rgb(6, 95, 212);
}

.data-panel-settings .settings-button {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  min-height: 34px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(6, 95, 212);
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 15px;
}
.data-panel-settings .settings-button:hover {
  border: 0;
  background-color: rgb(214, 221, 255);
  border: 1px solid rgb(214, 221, 255);
}
.data-panel-settings .settings-button:active {
  border: 0;
  background-color: rgb(105, 230, 209);
  border: 1px solid rgb(105, 230, 209);
}

.settings-container-outer {
  display: flex;
  justify-content: center;
}
.settings-container-outer .settings-container-inner {
  max-width: 915px;
}

@media screen and (max-width: 768px) {
  .wrap-768 {
    flex-direction: column;
  }
  .wrap-768 div:nth-child(1) {
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 768px) {
  .tax-input {
    align-items: start !important;
    flex-direction: column-reverse !important;
  }
  .tax-input .settings-item-option-action {
    margin-top: 4px;
  }
  .tax-input .settings-item-option-head {
    margin-bottom: 2px;
  }
}/*# sourceMappingURL=panel.css.map */