*, *::after, *::before {
  box-sizing: border-box;
}

/* body {
  overflow: hidden;
} */
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background-color: white;
}

input {
  outline: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

button {
  cursor: pointer;
  outline: none;
  overflow: visible;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  /* font-weight: normal; */
}

button:hover {
  outline: none;
  text-decoration: none;
}

button:active {
  outline: none;
  text-decoration: none;
}

button:focus {
  outline: none;
  text-decoration: none;
}

li {
  list-style: none;
}

table, td {
  border-collapse: collapse;
}

main {
  padding: 0;
}

.nowrap {
  white-space: nowrap !important;
}

body.freeze {
  overflow: hidden;
}

body {
  overflow-x: hidden;
  padding-right: 10px;
  width: 100vw;
}

@media screen and (max-width: 700px) {
  body {
    overflow-x: initial;
    padding-right: initial;
    width: initial;
  }
}/*# sourceMappingURL=config.css.map */