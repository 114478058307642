#mode-buttons {
  display: flex;
  @media screen and (max-width: 350px) {
    flex-direction: column;
  }

  div:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    @media screen and (max-width: 350px) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  div:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @media screen and (max-width: 350px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 4px;
    }
  }

  div.inactive {
    color: rgb(6, 95, 212);
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      border: 0;
      background-color: rgb(214, 221, 255);
    }

    &:active {
      border: 0;
      background-color: rgb(105, 230, 209);
    }
  }
}

.drag-n-drop {
  .app-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .item-list {
    list-style: none;
    padding: 0;
  }

  .list-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .list-item div {
    display: flex;
    align-items: center;
  }

  button {
    background-color: #065fd4;
    color: #fff;
    border: none;
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
  }

  button:hover {
    background-color: #0654ba;
  }

  button:active {
    background-color: #0864dc;
  }

  button.remove {
    background-color: #d93025;
  }

  button.remove:hover {
    background-color: #ac2a20;
  }

  button.remove:active {
    background-color: #e43e32;
  }

  .add-item {
    margin-top: 20px;
  }

  label {
    font-weight: bold;
    margin-right: 10px;
  }

  input[type="text"],
  input[type="number"],
  select {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  button[type="button"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }

  button[type="button"]:hover {
    background-color: #0056b3;
  }

  .add {
    margin-top: 10px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    color: rgb(48, 48, 48);
  }

  li.new-item-text {
    background-color: #d6dde7;
  }

  li.new-item-number {
    background-color: #f4c7ad;
  }

  img {
    margin: 0;
    height: 25px;
    margin-right: 10px;
    cursor: grab;
  }

  img.grip {
    @media screen and (max-width: 400px) {
      display: none;
    }
  }

  .arrow-buttons {
    display: none;
    button {
      &:nth-child(1) {
        margin-right: 10px;
      }
      img {
        height: 20px;
        width: 20px;
      }
    }
    @media screen and (max-width: 400px) {
      display: initial;
      display: flex;
    }
  }

  .li-content {
    display: grid;
    grid-template-columns: 250px 250px 200px;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;

      span:nth-child(1) {
        margin-bottom: 2px;
      }
    }
  }

  ul {
    li {
      @media screen and (max-width: 400px) {
        flex-direction: column;
      }
      img {
        @media screen and (max-width: 400px) {
          margin-right: 0;
        }
      }
      button {
        @media screen and (max-width: 400px) {
          margin-left: 0;
        }
      }
      .li-content {
        @media screen and (max-width: 900px) {
          grid-template-columns: 200px 200px;
          grid-template-rows: 40px 45px;
          align-items: center;
        }
        @media screen and (max-width: 600px) {
          grid-template-columns: 200px;
          grid-template-rows: 45px 45px 50px;
          align-items: center;
        }
        @media screen and (max-width: 300px) {
          grid-template-columns: 150px;
          grid-template-rows: 60px 45px 50px;
          align-items: center;
        }
      }
    }
  }

  .add {
    height: 34px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      height: fit-content;

      button {
        margin-left: 0;
      }

      select {
        margin-bottom: 5px;
      }

      input {
        margin-bottom: 5px;
      }
    }

    input {
      padding: 6.2px;
    }

    .numstl {
      width: 100%;
    }
  }
}