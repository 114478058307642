nav#nav-bar.hidden {
    display: none !important;
}

nav#nav-bar {
    height: fit-content;
    width: 100vw;
    padding-right: 30px;
    @media screen and (max-width: 700px) {
        // width: initial;
        padding-right: 15px;
    }
    div.nav-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 17.5px;
        padding-bottom: 17.5px;
        div.menu-img {
            display: none;
            @media screen and (max-width: 700px) {
                cursor: pointer;
                display: initial;
            }
            img {
                width: 24px;
                height: 24px;
                margin: 0;
            }
        }
        .first {
            margin: -4px;
            margin-left: 0;
            width: 24px;
            height: 24px;
            @media screen and (max-width: 700px) {
                display: none;
            }
            // @media screen and (max-width: 700px) {
            //     position: absolute;
            //     top: 5px;
            //     left: 50vw;
            //     transform: translate(-50%, -50%);
            //     width: 100px;
            //     height: 100px;
            //     overflow: hidden;
            // }
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 700px) {
                display: none;
            }
            li {
                padding: 0 100px;
                @media screen and (max-width: 1100px) {
                    padding: 0 80px;
                }
                @media screen and (max-width: 950px) {
                    padding: 0 60px;
                }
            }
        }
        div.logout {
            cursor: pointer;
            img {
                width: 20px;
                height: 20px;
                margin: 0;
            }
            img.google-img {
                border-radius: 100%;
                width: 28px;
                height: 28px;
                margin: -4px;
                margin-right: 0;
            }
            .user_image_wrapper {
                width: 20px;
                height: 20px;
                margin: 0;
                border-radius: 100%;
                width: 28px;
                height: 28px;
                margin: -4px;
                margin-right: 0;
                background-color: #b6b6b6;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    margin: 0;
                    width: 18px;
                    height: 18px;
                }
                &:active {
                    background-color: #d7d7d7;
                }
            }
        }
    }
    
    div.nav-mobile {
        display: none;
        max-height: 0;
    }
    div.nav-mobile.expanded {
        max-height: 100%;
        display: flex;
        justify-content: start;
        ul {
            display: flex;
            flex-direction: column;
            margin-bottom: 18px;
            li {
                display: flex;
                justify-content: start;
                text-align: left;
                margin-top: 18px;
                margin-bottom: 18px;
            }
        }
    }
}

.sign-out-diag {
    display: none;
}

.sign-out-diag + .diag-screen{
    display: none;
}

.sign-out-diag.expanded {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 56px + 5px;
    right: 10px;
    // background-color: #242526;
    // background-color: red;
    border-radius: 4px;
    padding: 0 15px;
    color: #dadce1;
    max-width: 300px;
    width: fit-content;
    min-width: 150px;
    z-index: 100;
    margin-left: 10px;
    @media screen and (max-width: 700px) {
        margin: 0;
        right: 0;
        top: 56px + 15px;
        max-width: none;
        min-width: none;
        width: 100%;
    }
    .user {
        font-weight: 700;
        user-select: none;
        cursor: default;
        font-size: 14px;
        display: flex;
        align-items: center;
        img {
            margin: 0;
            width: 28px;
            height: 28px;
            margin-right: 15px;
            border-radius: 100%;
        }
    }
    .line {
        width: 100%;
        border-bottom: 1px solid #dadce1;
        margin: 15px 0;
    }
    .sign-out-btn {
        user-select: none;
        cursor: pointer;
        font-size: 14px;
    }
}

.sign-out-diag.expanded + .diag-screen {
    z-index: 99;
    display: initial;
    background-color: rgba(255, 255, 255, 0.4);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
}

.sign-out-diag-inner {
    background-color: #242526;
    border-radius: 4px;
    padding: 15px;
    width: 100%;
}