
.panel {
    // color: #282c33 !important;
    color: #333333 !important;
    margin: 18px 24px;
    margin-bottom: 0;
    // box-shadow: 0px 0px 5px rgb(0,0,0,0.05);
    border: 1px solid rgb(0,0,0,0.1);
    border-radius: 4px;
    background-color: #ffff;
    @media screen and (max-width: 768px) {
        margin: 18px;
        margin-bottom: 0;
        .panel-heading {
            text-align: center;
        }
    }
    .panel-heading {
        // font-weight: 600;
        padding: 24px;
        border-bottom: 1px solid rgb(0,0,0,0.1);
    }
    .panel-content {
        padding: 24px;
    }
}

.data-panel-settings {
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 20px 24px 0;
    color: #606060;
    align-self: center;
    .settings-head {
        padding: 20px 0 40px;
        display: flex;
        flex-direction: column;
        .settings-heading {
            font-size: 24px;
            color: rgb(15,15,15);
        }
        .settings-subheading {
            padding-top: 8px;
        }
    }
    .settings-group {
        text-align: left;
        padding: 20px 0;
        border-top: 1px solid rgb(0,0,0,0.1);
        // max-width: 1000px;
        .settings-group-head {
            margin-bottom: 40px;
            .settings-group-heading {
                font-weight: 500;
                font-size: 16px;
                color: rgb(15,15,15);
            }
            .settings-group-sub-heading {
                padding-top: 8px;
            }
        }
        .settings-group-body {
            .settings-item {
                margin-bottom: 20px;
                display: grid;
                grid-template-rows: 1;
                grid-template-columns: 160px 1fr;
                @media screen and (max-width: 480px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: 1 1;
                    margin-top: 40px;
                }
                // &:last-child {
                //     margin-bottom: 20px;
                // }
                .settings-item-heading {
                    @media screen and (max-width: 480px) {
                        margin-bottom: 20px;
                    }
                    color: rgb(15,15,15);
                    min-width: 160px;
                    font-weight: 500;
                    text-align: left;
                }
                .settings-item-options {
                    @media screen and (max-width: 480px) {
                        padding-left: 0;
                    }
                    padding-left: 54px;
                    .settings-item-option {
                        max-width: 700px;
                        display: flex;
                        align-items: self-start;
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .settings-item-option-action {
                            margin-right: 20px;
                            input {
                                background-color: transparent;
                                width: 200px;
                                height: 46px;
                                border-radius: 8px;
                                border: 1px solid rgb(0,0,0,0.1);
                                padding: 0 18px;
                            }
                        }
                        .settings-item-option-head {
                            display: flex;
                            flex-direction: column;
                            .settings-item-option-heading {
                                color: rgb(15,15,15);
                                font-weight: 500;
                                padding-bottom: 4px;
                                &.action {
                                    cursor: pointer;
                                    color: rgb(62, 166, 255);
                                    color: rgb(6, 95, 212);
                                }
                            }
                            // .settings-item-option-subheading {
                            //     padding-top: 4px;
                            // }
                        }
                    }
                }
            }
        }
    }
}

.data-panel-settings .settings-button {
    user-select: none;
    cursor: pointer;
    min-height: 34px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(6, 95, 212);
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(0,0,0,0.1);
    border-radius: 4px;
    background-color: rgb(0,0,0,0);
    white-space: nowrap;
    width: fit-content;
    padding: 0 15px;
    &:hover {
        border: 0;
        background-color: rgb(214, 221, 255);
        border: 1px solid rgb(214, 221, 255);
    }
    &:active {
        border: 0;
        background-color: rgb(105, 230, 209);
        border: 1px solid rgb(105, 230, 209);
    }
}

.settings-container-outer {
    display: flex;
    justify-content: center;
    .settings-container-inner {
        max-width: 915px;
    }
}

.wrap-768 {
    @media screen and (max-width: 768px) {
        flex-direction: column;
        
        div:nth-child(1) {
            margin-bottom: 4px;
        }
    }
}

.tax-input {
    @media screen and (max-width: 768px) {
        align-items: start !important;
        flex-direction: column-reverse !important;

        .settings-item-option-action {
            margin-top: 4px;
        }

        .settings-item-option-head {
            margin-bottom: 2px;
        }
    }
}