// Subscribe
body.subscription-page {
    .plans {
        display: flex;
        @media screen and (max-width: 915px) {
            flex-direction: column;
        }
        .plan-panel {
            border-radius: 8px;
            // border: 1px solid #ccc;
            border: 1px solid rgb(0,0,0,0.1);
            height: fit-content;
            width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:nth-child(1) {
                margin-right: 18px;
            }
            @media screen and (max-width: 915px) {
                width: initial;
                &:nth-child(1) {
                    margin-right: 0;
                    margin-bottom: 18px;
                }
            }
            .title {
                width: 80%;
                padding: 24px 0;
                display: flex;
                align-items: center;
                img {
                    margin: 0;
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                    margin-right: 10px;
                }
                .title-heading_description {
                    display: flex;
                    flex-direction: column;
                    .title-heading {
                        color: #333333;
                        font-size: 18px;
                    }
                    .title-description {
                        font-size: 12px;
                        font-weight: 400;
                        color: #606060;
                    }
                }
            }
            .features.br {
                padding-top: 18.8px;
                padding-bottom: 18.8px;
                // padding-bottom: 37.6px;
                @media screen and (max-width: 915px) {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
            .features {
                width: 80%;
                margin-top: 12px;
                margin-left: 36px;
                padding-right: 36px;
                margin-bottom: 36px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .feature {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                    .check {
                        background-color: #606060;
                        border-radius: 50%;
                        width: 10px;
                        height: 10px;
                        min-width: 10px;
                        min-height: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                        img {
                            margin: 0;
                            width: 7px;
                            height: 7px;
                        }
                    }
                    div {
                        font-weight: 400;
                        font-size: 12px;
                        color: #606060;
                    }
                }
            }
            .action-btn {
                user-select: none;
                cursor: pointer;
                min-height: 34px;
                min-width: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 14px;
                font-weight: 500;
                border-radius: 8px;
                background-color: #065fd4;
                width: 80%;
                margin: 24px 0;
                &:hover {
                    border: 0;
                    background-color: #0654ba;
                }
                &:active {
                    border: 0;
                    background-color: #0864dc;
                }
                img {
                    margin: 0;
                    transform: rotate(180deg);
                    width: 15px;
                    margin-left: 10px;
                }
            }
        }
    }
}

form.subscription-form {
    display: flex;
    flex-direction: column;
    input[type="radio"] {
        display: none;
    }
    label {
        user-select: none;
        display: inline-block;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
    }
    label.br {
        margin-bottom: 18px;
    }
    input[type="radio"]:checked + label {
        border: 1px solid #428bca;
        box-shadow: 0 0 0 1px #428bca;
    }
    input[type="radio"]:checked + label > .title_check > .input-check {
        background-color: #428bca;
        border: 1px solid #428bca;
    }
}

form.subscription-form {
    width: 80%;
    label {
        height: 76px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr;
        @media screen and (max-width: 458px) {
            height: 76px + 13px;
        }
        @media screen and (max-width: 372px) {
            height: 76px + 13px + 13px;
        }
        @media screen and (max-width: 350px) {
            height: 76px + 13px + 13px + 13px;
        }
    }
    .title_check {
        grid-column: 1;
        grid-row: 1;
        display: flex;
        .input-check {
            width: fit-content;
            height: fit-content;
            border: 1px solid #ccc;
            border-radius: 100%;
            background-color: white;
            img {
                width: 10px;
                height: 10px;
                margin: 3px;
            }
        }
        .input-title {
            margin-left: 10px;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .input-description {
        grid-column: 1;
        grid-row: 2;
        color: #606060;
        font-size: 12px;
        margin-left: 26px;
        font-weight: 400;
        padding-right: 10px;
    }
    .price_period {
        grid-column: 2;
        grid-row: 1;
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 384px) {
            white-space: nowrap;
        }
        .input-price::before {
            content: "€";
            font-size: 10px;
            font-weight: 400;
            color: #606060;
            position: relative;
            bottom: 8px;
            right: 1px;
        }
        .input-price {
            font-size: 20px;
            font-weight: bold;
        }
        .input-period {
            font-size: 10px;
            font-weight: 400;
            color: #606060;
            position: relative;
            top: 10px;
        }
    }
    .input-label {
        grid-column: 2;
        grid-row: 2;
        font-size: 10px;
        font-weight: 400;
        justify-self: end;
        align-self: center;
        border-radius: 12px;
        padding: 6px 10px 5px;
        color: white;
        background-color: rgb(130, 178, 80);
        text-align: center;
        // @media screen and (max-width: 384px) {
        //     font-size: 9px;
        // }
        @media screen and (max-width: 340px) {
            margin-top: -3px;
        }
        @media screen and (max-width: 340px) {
            margin-top: -5px;
        }
        @media screen and (max-width: 316px) {
            margin-top: -7px;
        }
    }
}

form.subscription-form {
    .subscribed::after {
        content: "ΕΝΕΡΓΟ ΠΑΚΕΤΟ";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) rotate(-12deg);
        color: red;
        font-weight: 600;
        font-size: 17px;
        white-space: nowrap;
        @media screen and (max-width: 300px) {
            white-space: break-spaces;
            line-height: 120%;
        }
    }
    .subscribed {
        user-select: none;
        position: relative;
        .title_check {
            .input-check {
                opacity: 50%;
                width: 17.5px;
                height: 17.5px;
                img {
                    display: none;
                }
            }
            .input-title {
                opacity: 50%;
            }
        }
        .input-description {
            opacity: 50%;
        }
        .price_period {
            .input-price::before {
                opacity: 50%;
            }
            .input-price {
                opacity: 50%;
            }
            .input-period {
                opacity: 50%;
            }
        }
        .input-label {
            opacity: 50%;
        }
        cursor: default;
        border: 1px solid #ccc;
        background-color: #e6e6e6;
        z-index: -1;
    }
}
.settings-head {
    position: relative;
}
div.back-arrow {
    position: absolute;
    top: 20px;
    left: 40px;
    cursor: pointer;
    @media screen and (max-width: 915px) {
        left: 0px;
    }
    @media screen and (max-width: 340px) {
        left: -8px;
        top: -10px;
    }
    img {
        margin: 0;
    }
}

// SubscriptionOptions

body.subscription-page {
    .center-flex {
        display: flex !important;
        // justify-content: center;
    }
    #active-sub {
        width: fit-content;
    }
    .settings-item-option-wrapper {
        display: flex;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        // box-shadow: 0 0 0 1px #ccc;
        box-shadow: 0;
        .settings-item-option-action {
            margin-right: 10px !important;
        }
        .settings-item-option-inner-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .settings-item-option-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 3px;
                .interval {
                    font-weight: 800;
                    color: #606060;
                    font-size: 10px;
                    // margin-left: 10px;
                }
            }
            .settings-item-option-subheading {
                font-size: 13px;
                color: #606060;
            }
        }
    }
    img.sub-icon {
        margin: 0;
        width: 40px;
        height: 40px;
        border-radius: 10px;        
    }
}

.toBeCanceled {
    .settings-button.undo-cancel {
        user-select: none;
        cursor: pointer;
        // padding-left: 20px;
        // padding-right: 20px;
        // width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 14px;
        font-weight: 500;
        // border-radius: 8px;
        border: 0;
        background-color: #065fd4;
        &:hover {
            border: 0;
            background-color: #0654ba;
        }
        &:active {
            border: 0;
            background-color: #0864dc;
        }
    }
}

.settings-button.portal {
    user-select: none;
    cursor: pointer;
    // min-height: 34px;
    min-width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    // color: white;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    // background-color: #065fd4;
    // width: 149px;
    padding-left: 10px;
    padding-right: 10px;
    // margin: 24px 0;
    &:hover {
        border: 0;
        // background-color: #0654ba;
    }
    &:active {
        border: 0;
        // background-color: #0864dc;
    }
    img {
        margin: 0;
        transform: rotate(180deg);
        width: 15px;
        margin-left: 10px;
    }
}

.settings-button.portal.blue {
    color: white;
    border: 0;
    background-color: #065fd4;
    &:hover {
        border: 0;
        background-color: #0654ba;
    }
    &:active {
        border: 0;
        background-color: #0864dc;
    }
}