.inactive-5 {
    opacity: 0.5;
    cursor: default;
}
.inactive-7 {
    opacity: 0.7;
    cursor: default;
}

#back-button.hidden {
    display: none !important;
}

.fill {
    width: 100vw;
    overflow-x: hidden;
    background-color: #ECEFF1;
    height: 15px;
    z-index: 2;
    transform: translateY(-15px);
    @media screen and (max-width: 700px) {
        transform: translateY(-15px);
    }
}
.login-cargr {
    z-index: 1;
    width: 100vw;
    overflow-x: hidden;
    background-color: #ECEFF1;
    font-family: verdana, arial, sans-serif;
    font-weight: 700;
    height: 100vh;
    // width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-15px);
    @media screen and (max-width: 700px) {
        transform: translateY(-15px);
    }

    .container {
        z-index: 3;
        background-color: white;
        max-width: 575px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        border-radius: 4px;
        @media screen and (max-width: 300px) {
            width: 90%;
        }

        .container__head {
            border-bottom: solid 1px #d4d4d4;
            h2 {
                font-size: 18px;
                // color: rgb(51, 51, 153);
                color: #0652b6;
                text-align: center;
                padding: 12px;
            }
        }

        .container__alert {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 2px;
            height: 75px;
            overflow: hidden;
            margin: 0;
            max-height: 0;
            // transition: max-height 0.5s;
            .status {
                display: flex;
                justify-content: center;
                align-items: center;
                // gap: 15px;
                margin: 10px;
                p {
                    font-size: 12px;
                    font-weight: 600;
                }
                img {
                    padding-left: 15px;
                    height: 20px !important;
                    display: none;
                }
            }
        
            &.container__alert-active {
                max-height: 100%;
                margin: 15px 25px 0;
            }
            &.loading {
                background-color: whitesmoke;
                border: 1px solid #d4d4d4;
                p {
                    color: rgb(51, 51, 51);
                }
                img {
                    display: initial;
                    margin: 0;
                }
            }
            &.ok {
                background-color: #dff0d8;
                border: 1px solid #d6e9c6;
                p {
                    color: #468847;
                }
            }
            &.err {
                background-color: #f2dede;
                border: 1px solid #eed3d7;
                p {
                    color: #b94a48;
                }
            }
        }

        .container__form {
            padding: 15px 25px;
            display: flex;
            flex-direction: column;
            gap: 7.5px;
            .form__row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 410px) {
                    flex-direction: column;
                    align-items: start;
                }
                .form-row__heading {
                    margin-right: 20px;
                    font-size: 13px;
                    color: rgb(51, 51, 51);
                    @media screen and (max-width: 410px) {
                        margin-bottom: 5px;
                    }
                }
                div {
                    @media screen and (max-width: 300px) {
                        width: 100%;
                    }
                }
                input {
                    @media screen and (max-width: 300px) {
                        width: 100%;
                    }
                    font-size: 12px;
                    color: rgb(85, 85, 85);
                    border: 1px solid #ccc;
                    height: 34px;
                    width: 220px;
                    border-radius: 4px;
                    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
                    
                    transition: 
                        border-color ease-in-out .15s, 
                        box-shadow ease-in-out .15s;
                }
                input:focus {
                    border: 1px solid #66afe9;
                    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
                }
            }
        
            .form__container-login-btn {
                display: flex;
                justify-content: right;
                padding-top: 7px;
                button {
                    padding: 6px 12px;
                    font-size: 13px;
                    font-weight: 500;
                    font-weight: normal;
                    color: white;
                    // background-color: #004e95;
                    border-radius: 4px;
                    height: 34px;
                    width: 220px;
                    border-radius: 8px;
                    background-color: #065fd4;
                    &:hover {
                        border: 0;
                        background-color: #0654ba;
                    }
                    &:active {
                        border: 0;
                        background-color: #0864dc;
                    }
                }
            }
        }
    }
    .bottom-label {
        z-index: 3;
        padding-top: 18px;
        h1 {
            color: #bebebe;
            font-size: 13px;
            font-weight: 400;
        }
        h2 {
            margin-top: 15px;
            color: #0652b6;
            font-size: 13px;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.cargr-connect {
    overflow: hidden;
}
