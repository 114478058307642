@charset "UTF-8";
body.subscription-page .plans {
  display: flex;
}
@media screen and (max-width: 915px) {
  body.subscription-page .plans {
    flex-direction: column;
  }
}
body.subscription-page .plans .plan-panel {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: -moz-fit-content;
  height: fit-content;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
body.subscription-page .plans .plan-panel:nth-child(1) {
  margin-right: 18px;
}
@media screen and (max-width: 915px) {
  body.subscription-page .plans .plan-panel {
    width: initial;
  }
  body.subscription-page .plans .plan-panel:nth-child(1) {
    margin-right: 0;
    margin-bottom: 18px;
  }
}
body.subscription-page .plans .plan-panel .title {
  width: 80%;
  padding: 24px 0;
  display: flex;
  align-items: center;
}
body.subscription-page .plans .plan-panel .title img {
  margin: 0;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
}
body.subscription-page .plans .plan-panel .title .title-heading_description {
  display: flex;
  flex-direction: column;
}
body.subscription-page .plans .plan-panel .title .title-heading_description .title-heading {
  color: #333333;
  font-size: 18px;
}
body.subscription-page .plans .plan-panel .title .title-heading_description .title-description {
  font-size: 12px;
  font-weight: 400;
  color: #606060;
}
body.subscription-page .plans .plan-panel .features.br {
  padding-top: 18.8px;
  padding-bottom: 18.8px;
}
@media screen and (max-width: 915px) {
  body.subscription-page .plans .plan-panel .features.br {
    padding-top: 0;
    padding-bottom: 0;
  }
}
body.subscription-page .plans .plan-panel .features {
  width: 80%;
  margin-top: 12px;
  margin-left: 36px;
  padding-right: 36px;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
body.subscription-page .plans .plan-panel .features .feature {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
body.subscription-page .plans .plan-panel .features .feature:nth-last-child(1) {
  margin-bottom: 0;
}
body.subscription-page .plans .plan-panel .features .feature .check {
  background-color: #606060;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
body.subscription-page .plans .plan-panel .features .feature .check img {
  margin: 0;
  width: 7px;
  height: 7px;
}
body.subscription-page .plans .plan-panel .features .feature div {
  font-weight: 400;
  font-size: 12px;
  color: #606060;
}
body.subscription-page .plans .plan-panel .action-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  min-height: 34px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  background-color: #065fd4;
  width: 80%;
  margin: 24px 0;
}
body.subscription-page .plans .plan-panel .action-btn:hover {
  border: 0;
  background-color: #0654ba;
}
body.subscription-page .plans .plan-panel .action-btn:active {
  border: 0;
  background-color: #0864dc;
}
body.subscription-page .plans .plan-panel .action-btn img {
  margin: 0;
  transform: rotate(180deg);
  width: 15px;
  margin-left: 10px;
}

form.subscription-form {
  display: flex;
  flex-direction: column;
}
form.subscription-form input[type=radio] {
  display: none;
}
form.subscription-form label {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: inline-block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
form.subscription-form label.br {
  margin-bottom: 18px;
}
form.subscription-form input[type=radio]:checked + label {
  border: 1px solid #428bca;
  box-shadow: 0 0 0 1px #428bca;
}
form.subscription-form input[type=radio]:checked + label > .title_check > .input-check {
  background-color: #428bca;
  border: 1px solid #428bca;
}

form.subscription-form {
  width: 80%;
}
form.subscription-form label {
  height: 76px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
}
@media screen and (max-width: 458px) {
  form.subscription-form label {
    height: 89px;
  }
}
@media screen and (max-width: 372px) {
  form.subscription-form label {
    height: 102px;
  }
}
@media screen and (max-width: 350px) {
  form.subscription-form label {
    height: 115px;
  }
}
form.subscription-form .title_check {
  grid-column: 1;
  grid-row: 1;
  display: flex;
}
form.subscription-form .title_check .input-check {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #ccc;
  border-radius: 100%;
  background-color: white;
}
form.subscription-form .title_check .input-check img {
  width: 10px;
  height: 10px;
  margin: 3px;
}
form.subscription-form .title_check .input-title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}
form.subscription-form .input-description {
  grid-column: 1;
  grid-row: 2;
  color: #606060;
  font-size: 12px;
  margin-left: 26px;
  font-weight: 400;
  padding-right: 10px;
}
form.subscription-form .price_period {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 384px) {
  form.subscription-form .price_period {
    white-space: nowrap;
  }
}
form.subscription-form .price_period .input-price::before {
  content: "€";
  font-size: 10px;
  font-weight: 400;
  color: #606060;
  position: relative;
  bottom: 8px;
  right: 1px;
}
form.subscription-form .price_period .input-price {
  font-size: 20px;
  font-weight: bold;
}
form.subscription-form .price_period .input-period {
  font-size: 10px;
  font-weight: 400;
  color: #606060;
  position: relative;
  top: 10px;
}
form.subscription-form .input-label {
  grid-column: 2;
  grid-row: 2;
  font-size: 10px;
  font-weight: 400;
  justify-self: end;
  align-self: center;
  border-radius: 12px;
  padding: 6px 10px 5px;
  color: white;
  background-color: rgb(130, 178, 80);
  text-align: center;
}
@media screen and (max-width: 340px) {
  form.subscription-form .input-label {
    margin-top: -3px;
  }
}
@media screen and (max-width: 340px) {
  form.subscription-form .input-label {
    margin-top: -5px;
  }
}
@media screen and (max-width: 316px) {
  form.subscription-form .input-label {
    margin-top: -7px;
  }
}

form.subscription-form .subscribed::after {
  content: "ΕΝΕΡΓΟ ΠΑΚΕΤΟ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(-12deg);
  color: red;
  font-weight: 600;
  font-size: 17px;
  white-space: nowrap;
}
@media screen and (max-width: 300px) {
  form.subscription-form .subscribed::after {
    white-space: break-spaces;
    line-height: 120%;
  }
}
form.subscription-form .subscribed {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: relative;
  cursor: default;
  border: 1px solid #ccc;
  background-color: #e6e6e6;
  z-index: -1;
}
form.subscription-form .subscribed .title_check .input-check {
  opacity: 50%;
  width: 17.5px;
  height: 17.5px;
}
form.subscription-form .subscribed .title_check .input-check img {
  display: none;
}
form.subscription-form .subscribed .title_check .input-title {
  opacity: 50%;
}
form.subscription-form .subscribed .input-description {
  opacity: 50%;
}
form.subscription-form .subscribed .price_period .input-price::before {
  opacity: 50%;
}
form.subscription-form .subscribed .price_period .input-price {
  opacity: 50%;
}
form.subscription-form .subscribed .price_period .input-period {
  opacity: 50%;
}
form.subscription-form .subscribed .input-label {
  opacity: 50%;
}

.settings-head {
  position: relative;
}

div.back-arrow {
  position: absolute;
  top: 20px;
  left: 40px;
  cursor: pointer;
}
@media screen and (max-width: 915px) {
  div.back-arrow {
    left: 0px;
  }
}
@media screen and (max-width: 340px) {
  div.back-arrow {
    left: -8px;
    top: -10px;
  }
}
div.back-arrow img {
  margin: 0;
}

body.subscription-page .center-flex {
  display: flex !important;
}
body.subscription-page #active-sub {
  width: -moz-fit-content;
  width: fit-content;
}
body.subscription-page .settings-item-option-wrapper {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0;
}
body.subscription-page .settings-item-option-wrapper .settings-item-option-action {
  margin-right: 10px !important;
}
body.subscription-page .settings-item-option-wrapper .settings-item-option-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
body.subscription-page .settings-item-option-wrapper .settings-item-option-inner-wrapper .settings-item-option-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}
body.subscription-page .settings-item-option-wrapper .settings-item-option-inner-wrapper .settings-item-option-head .interval {
  font-weight: 800;
  color: #606060;
  font-size: 10px;
}
body.subscription-page .settings-item-option-wrapper .settings-item-option-inner-wrapper .settings-item-option-subheading {
  font-size: 13px;
  color: #606060;
}
body.subscription-page img.sub-icon {
  margin: 0;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.toBeCanceled .settings-button.undo-cancel {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  background-color: #065fd4;
}
.toBeCanceled .settings-button.undo-cancel:hover {
  border: 0;
  background-color: #0654ba;
}
.toBeCanceled .settings-button.undo-cancel:active {
  border: 0;
  background-color: #0864dc;
}

.settings-button.portal {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
}
.settings-button.portal:hover {
  border: 0;
}
.settings-button.portal:active {
  border: 0;
}
.settings-button.portal img {
  margin: 0;
  transform: rotate(180deg);
  width: 15px;
  margin-left: 10px;
}

.settings-button.portal.blue {
  color: white;
  border: 0;
  background-color: #065fd4;
}
.settings-button.portal.blue:hover {
  border: 0;
  background-color: #0654ba;
}
.settings-button.portal.blue:active {
  border: 0;
  background-color: #0864dc;
}/*# sourceMappingURL=subscription.css.map */