body.login-page {
  overflow: hidden;
  font-family: verdana, arial, sans-serif;
  background-color: #1a3644;
  font-weight: 700;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
}
body.login-page #login {
  transform: translateY(-30px);
  position: relative;
}
body.login-page #wrapper-background-image {
  overflow: visible;
  width: 100%;
  height: 100%;
  opacity: 4%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.login-page .wrapper-front {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.login-page .wrapper-front .header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
body.login-page .wrapper-front .header .heading {
  color: white;
  font-weight: 500;
}
body.login-page .wrapper-front .header .request-account > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
body.login-page .wrapper-front .header .request-account > div div {
  color: white !important;
}
body.login-page .wrapper-front .header .request-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}
body.login-page .wrapper-front .header .request-account div {
  display: flex;
  gap: 5px;
}
body.login-page .wrapper-front .header .request-account div:nth-child(2) {
  color: rgb(119, 119, 119);
}
body.login-page .wrapper-front .header .request-account div a {
  color: #4285f4;
  text-decoration: underline;
}
body.login-page .wrapper-front .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 30px;
  border-radius: 2px;
}
body.login-page .wrapper-front .container .google-login {
  display: flex;
  justify-content: center;
  width: 100%;
}
body.login-page .wrapper-front .container .divider-line {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  border-bottom: 1px solid rgb(212, 212, 212);
  display: flex;
  justify-content: center;
  display: none;
}
body.login-page .wrapper-front .container .divider-line .text {
  padding: 0 7px;
  background-color: white;
  color: rgb(119, 119, 119);
  text-align: center;
  position: absolute;
  transform: translate3d(0, -8px, 0);
}
body.login-page .wrapper-front .container .login-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: none;
}
body.login-page .wrapper-front .container .login-field .login-field__heading {
  z-index: 1;
  position: absolute;
  color: rgb(119, 119, 119);
  transform: translate3d(10px, 0, 0);
  transition: all 0.1s;
}
body.login-page .wrapper-front .container .login-field .login-field__input {
  z-index: 2;
  background-color: transparent;
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgb(212, 212, 212);
  padding: 15px 10px;
}
body.login-page .wrapper-front .container .login-field .login-field__input:focus + .login-field__heading {
  transform: translate3d(10px, -25px, 0);
  font-size: 10px;
}
body.login-page .wrapper-front .container .login-field .login-field__input:valid + .login-field__heading {
  transform: translate3d(10px, -25px, 0);
  font-size: 10px;
}
body.login-page .wrapper-front .container .login-options {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgb(55, 55, 55);
  display: none;
}
body.login-page .wrapper-front .container .forgot-password {
  margin-top: 15px;
  color: #4285f4;
  text-decoration: underline;
  display: none;
}
body.login-page .wrapper-front .container .login-btn {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  height: 50px;
  width: 240px;
  color: white;
  background-color: #4285f4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-size: 14px;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}
body.login-page .wrapper-front .container .login-btn:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}
body.login-page .wrapper-front .container .login-btn:active {
  background-color: #3367d6;
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}
body.login-page .wrapper-front .container > .login-btn {
  display: none;
}
body.login-page .wrapper-front .g_logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  border-radius: 100%;
  padding: 3px;
  margin: -3px;
  margin-right: 15px;
  min-width: 20px;
  min-height: 20px;
}
body.login-page .wrapper-front .g_logo-container .g_logo {
  margin: -2px;
  width: 22px;
  height: 22px;
  min-width: 20px;
  min-height: 20px;
}/*# sourceMappingURL=login.css.map */