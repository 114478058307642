@charset "UTF-8";
.demo-banner {
  width: 100vw;
  background-color: rgb(220, 220, 50);
  padding: 5px;
  font-size: 90%;
}
@media screen and (max-width: 768px) {
  .demo-banner {
    padding: 10px 20px;
  }
}

.data-panel1 {
  margin-top: 36px;
}
.data-panel1 .panel-heading {
  font-size: 24px;
}
.data-panel1 .panel-content {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 890px) {
  .data-panel1 .panel-content {
    flex-direction: column;
  }
  .data-panel1 .panel-content .info-box {
    margin-bottom: 24px;
  }
  .data-panel1 .panel-content .info-box:nth-child(3) {
    margin-bottom: 0;
  }
}
.data-panel1 .panel-content .info-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 890px) {
  .data-panel1 .panel-content .info-box {
    justify-content: flex-start;
  }
}
.data-panel1 .panel-content .info-box .info-box-img {
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
.data-panel1 .panel-content .info-box .info-box-img img {
  height: 30px;
  width: 30px;
}
.data-panel1 .panel-content .info-box .info-box-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.data-panel1 .panel-content .info-box .info-box-right .info-box-heading {
  font-size: 12px;
}
.data-panel1 .panel-content .info-box .info-box-right .info-box-content {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 4px;
}
.data-panel1 .panel-content .info-box .info-box-right .info-box-bottom {
  font-size: 12px;
}

.data-panel2 {
  margin-top: 36px;
  margin-bottom: 36px;
  border: 0;
}
.data-panel2 .panel-heading {
  font-size: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.data-panel2 .panel-content {
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.data-panel2 .panel-content table {
  font-size: 14px;
  width: 100%;
}
.data-panel2 .panel-content table thead tr {
  padding: 24px 0;
  display: grid;
  grid-template-rows: 1fr;
  justify-items: start;
  align-items: center;
  grid-template-columns: 0.75fr 1fr 1fr 140px;
}
@media screen and (max-width: 1200px) {
  .data-panel2 .panel-content table thead tr {
    grid-template-columns: 0.7fr 1fr 1fr 140px;
  }
}
@media screen and (max-width: 1050px) {
  .data-panel2 .panel-content table thead tr {
    grid-template-columns: 0.65fr 1fr 1fr 140px;
  }
}
@media screen and (max-width: 900px) {
  .data-panel2 .panel-content table thead tr {
    grid-template-columns: 0.6fr 1fr 1fr 140px;
  }
}
@media screen and (max-width: 800px) {
  .data-panel2 .panel-content table thead tr {
    grid-template-columns: 0.55fr 1fr 1fr 140px;
  }
}
@media screen and (max-width: 700px) {
  .data-panel2 .panel-content table thead tr {
    grid-template-columns: 0.5fr 1fr 1fr 140px;
  }
}
.data-panel2 .panel-content table thead tr th {
  color: #606060;
  font-weight: 800;
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
}
.data-panel2 .panel-content table tbody .line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: none;
}
.data-panel2 .panel-content table tbody tr {
  padding: 20px 0;
  display: grid;
  grid-template-rows: 1fr;
  justify-items: start;
  align-items: center;
  grid-template-columns: 0.75fr 1fr 1fr 140px;
}
.data-panel2 .panel-content table tbody tr:nth-of-type(odd) {
  border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: rgba(40, 47, 95, 0.05);
}
@media screen and (max-width: 1200px) {
  .data-panel2 .panel-content table tbody tr {
    grid-template-columns: 0.7fr 1fr 1fr 140px;
  }
}
@media screen and (max-width: 1050px) {
  .data-panel2 .panel-content table tbody tr {
    grid-template-columns: 0.65fr 1fr 1fr 140px;
  }
}
@media screen and (max-width: 900px) {
  .data-panel2 .panel-content table tbody tr {
    grid-template-columns: 0.6fr 1fr 1fr 140px;
  }
}
@media screen and (max-width: 800px) {
  .data-panel2 .panel-content table tbody tr {
    grid-template-columns: 0.55fr 1fr 1fr 140px;
  }
}
@media screen and (max-width: 700px) {
  .data-panel2 .panel-content table tbody tr {
    grid-template-columns: 0.5fr 1fr 1fr 140px;
  }
}
.data-panel2 .panel-content table tbody tr td {
  grid-row: 1;
  margin-left: 15px;
  margin-right: 15px;
}
.data-panel2 .panel-content table tbody tr td.no {
  text-align: left;
  font-weight: 600;
}
.data-panel2 .panel-content table tbody tr td.date_time {
  text-align: left;
}
.data-panel2 .panel-content table tbody tr td.date_time .date_time-inner {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
}
.data-panel2 .panel-content table tbody tr td.date_time .date_time-inner .day-of-week::after {
  content: "•";
  margin: 0 5px;
}
.data-panel2 .panel-content table tbody tr td.date_time .date_time-inner .date::after {
  content: "•";
  margin: 0 5px;
}
.data-panel2 .panel-content table tbody tr td.category {
  text-align: left;
  background-color: rgba(124, 185, 199, 0.2);
  padding: 8px;
  margin: -8px;
  margin-left: 8px;
  border-radius: 16px;
}
.data-panel2 .panel-content table tbody tr td.category .number-updated-inner {
  display: none;
}
.data-panel2 .panel-content table tbody tr td.number-updated .number-updated-inner {
  display: flex;
  align-items: center;
}
.data-panel2 .panel-content table tbody tr td.number-updated .number-updated-inner .check {
  margin: 0;
  background-color: #606060;
  border-radius: 100%;
  padding: 1px;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.data-panel2 .panel-content table tbody tr td.number-updated .number-updated-inner span {
  color: #606060;
  color: rgb(51, 51, 51);
}

@media screen and (max-width: 650px) {
  .data-panel2 {
    border: 0;
  }
  .data-panel2 .panel-heading {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 0;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .data-panel2 .panel-content {
    padding: 0;
  }
  .data-panel2 .panel-content table thead {
    display: none;
  }
  .data-panel2 .panel-content table tbody .line:nth-child(1) {
    display: none;
  }
  .data-panel2 .panel-content table tbody .line {
    display: none;
  }
  .data-panel2 .panel-content table tbody tr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 0;
    border-radius: 4px;
  }
  .data-panel2 .panel-content table tbody tr td {
    margin-left: 20px !important;
    margin-right: 20px !important;
    padding: 0;
    margin-bottom: 4px;
  }
  .data-panel2 .panel-content table tbody tr td:nth-last-child(1) {
    margin-bottom: 0;
  }
  .data-panel2 .panel-content table tbody tr td.no.active::before {
    content: "Ανανέωση";
    margin-right: 5px;
  }
  .data-panel2 .panel-content table tbody tr td.date_time {
    color: #606060;
  }
  .data-panel2 .panel-content table tbody tr td.category {
    margin-left: 15px;
    font-size: 90%;
    color: #606060;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .data-panel2 .panel-content table tbody tr td.category .number-updated-inner {
    display: flex;
    align-items: center;
  }
  .data-panel2 .panel-content table tbody tr td.category .number-updated-inner::after {
    content: "•";
    margin: 0 5px;
  }
  .data-panel2 .panel-content table tbody tr td.category .number-updated-inner img {
    background-color: #606060;
    border-radius: 100%;
    padding: 1px;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 1px;
  }
  .data-panel2 .panel-content table tbody tr td.category .number-updated-inner span {
    color: rgb(51, 51, 51);
    color: #606060;
  }
  .data-panel2 .panel-content table tbody tr td.number-updated {
    display: none;
    grid-row: 3;
    grid-column: 1;
  }
  .data-panel2 .panel-content table tbody tr td.number-updated .number-updated-inner span::after {
    content: "•";
    margin: 0 5px;
  }
  .data-panel2 .panel-content table tbody tr.flat-b-r-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}/*# sourceMappingURL=home.css.map */