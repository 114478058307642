#loading.disabled {
  display: none;
}

#loading.enabled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading > .wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
#loading > .wrap img {
  margin: 0;
  width: 50px;
  height: 50px;
  z-index: 1000;
}
#loading > .wrap .msg {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #606060;
  z-index: 1000;
}

#loading > div.screen {
  display: initial;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 900;
}

#loading > div.main {
  background-color: white;
}/*# sourceMappingURL=loading.css.map */