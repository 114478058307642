body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms; 
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main, .form-inline {
  text-align: center;
  padding: 10px;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  display: block;
  margin: 10px auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul a {
  color: var(--text-color);
  text-decoration: none;;
}

/* Top Navigation Bar */

/* <nav> */
nav {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* <li> */
.navbar-nav li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-nav a {
  text-decoration: none;
}

.navbar-nav a:hover {
  color: white;
}

.well {
  max-width: 728px;
  border: solid 1px var(--text-color);
  border-radius: 6px;;
  padding: 1.5rem;
  margin: 10px auto;
}

.btn {
  margin: 10px 5px;
}

button {
  margin: 10px;
}

.vid {
  max-width: 728px;
  margin: 10px auto;
}