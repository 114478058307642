form.cargr-form {
  display: flex;
  flex-direction: column;
  gap: 1.5px;
}
form.cargr-form input[type=radio] {
  display: none;
}
form.cargr-form label {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
form.cargr-form input[type=radio]:checked + label {
  background-color: #eaf2f8;
  border-color: #428bca;
  color: #428bca;
  font-weight: bold;
}

.not-connected .line-wrap {
  display: flex;
  justify-content: center;
}
.not-connected .line-wrap .line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 400px;
}
@media screen and (max-width: 400px) {
  .not-connected .line-wrap .line {
    width: 100%;
  }
}
.not-connected .settings-group {
  border: 0;
  display: flex;
  justify-content: center;
}
.not-connected .settings-group a {
  width: 100%;
  display: flex;
  justify-content: center;
}
.not-connected .settings-group .settings-button {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  min-height: 34px;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin: 24px 0;
  border-radius: 8px;
  border: 0;
  background-color: #065fd4;
}
@media screen and (max-width: 300px) {
  .not-connected .settings-group .settings-button {
    min-width: 200px;
  }
}
@media screen and (max-width: 250px) {
  .not-connected .settings-group .settings-button {
    min-width: 150px;
  }
}
@media screen and (max-width: 200px) {
  .not-connected .settings-group .settings-button {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}
.not-connected .settings-group .settings-button:hover {
  border: 0;
  background-color: #0654ba;
}
.not-connected .settings-group .settings-button:active {
  border: 0;
  background-color: #0864dc;
}
.not-connected .settings-group .settings-button img {
  margin: 0;
  transform: rotate(180deg);
  width: 15px;
  margin-left: 10px;
}

.disconnected .settings-button {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  background-color: #065fd4;
}
.disconnected .settings-button:hover {
  border: 0;
  background-color: #0654ba;
}
.disconnected .settings-button:active {
  border: 0;
  background-color: #0864dc;
}
.disconnected .settings-button img {
  margin: 0;
  transform: rotate(180deg);
  width: 15px;
  margin-left: 10px;
}

.simple-settings-buttons {
  display: flex;
}
@media screen and (max-width: 350px) {
  .simple-settings-buttons {
    flex-direction: column;
  }
  .simple-settings-buttons .delete-keyword-button {
    margin-left: 0 !important;
    margin-top: 8px;
  }
}
.simple-settings-buttons .delete-keyword-button {
  margin-left: 20px;
  background-color: #d93025;
  color: white;
}
.simple-settings-buttons .delete-keyword-button:hover {
  background-color: #ac2a20;
}
.simple-settings-buttons .delete-keyword-button:active {
  background-color: #e43e32;
}/*# sourceMappingURL=cargr.css.map */