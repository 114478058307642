form.cargr-form {
    display: flex;
    flex-direction: column;
    gap: 1.5px;
  
  input[type="radio"] {
    display: none;
  }
  
  label {
    display: inline-block;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  input[type="radio"]:checked + label {
    background-color: #eaf2f8;
    border-color: #428bca;
    color: #428bca;
    font-weight: bold;
  }
}

.not-connected {
  .line-wrap {
    display: flex;
    justify-content: center;
    .line {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      width: 400px;
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }
  }
  .settings-group {
    border: 0;
    display: flex;
    justify-content: center;
    a {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .settings-button {
      user-select: none;
      cursor: pointer;
      min-height: 34px;
      min-width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 14px;
      font-weight: 500;
      margin: 24px 0;
      border-radius: 8px;
      border: 0;
      background-color: #065fd4;
      @media screen and (max-width: 300px) {
        min-width: 200px;
      }
      @media screen and (max-width: 250px) {
        min-width: 150px;
      }
      @media screen and (max-width: 200px) {
        min-width: fit-content;
      }
      &:hover {
          border: 0;
          background-color: #0654ba;
      }
      &:active {
          border: 0;
          background-color: #0864dc;
      }
      img {
          margin: 0;
          transform: rotate(180deg);
          width: 15px;
          margin-left: 10px;
      }
    }
  }
}

.disconnected {
  .settings-button {
    user-select: none;
    cursor: pointer;
    // padding-left: 20px;
    // padding-right: 20px;
    // width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    // border-radius: 8px;
    border: 0;
    background-color: #065fd4;
    &:hover {
        border: 0;
        background-color: #0654ba;
    }
    &:active {
        border: 0;
        background-color: #0864dc;
    }
    img {
        margin: 0;
        transform: rotate(180deg);
        width: 15px;
        margin-left: 10px;
    }
  }
}

.simple-settings-buttons {
  display: flex;
  @media screen and (max-width: 350px) {
    .delete-keyword-button {
      margin-left: 0 !important;
      margin-top: 8px;
    }
    flex-direction: column;
  }
  .delete-keyword-button {
    margin-left: 20px;
    background-color: #d93025;
    color: white;
    &:hover {
      background-color: #ac2a20;
    }
    &:active {
      background-color: #e43e32;
    }
  }
}

// .not-connected {
//   height: 100vh;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   margin-top: -15px;
//   .settings-head {
//     padding-top: 0 !important;
//   }
// }
